import ApiResource from '@/core/http/ApiResource';
import { TicketAttachment } from '@/modules/ticket/Ticket';

interface TicketCommentUserStub {
	id: number;
	name: string;
}

export interface TicketComment {
	id: number;
	message: string;
	private: boolean;
	created_time: string;
	attachments: TicketAttachment[];
	user: TicketCommentUserStub | undefined;
	upload_session_key: string | undefined;
}

export class TicketCommentResource extends ApiResource<TicketComment> {

	postAttachment(commentId: number, uploadKey: string, data: File, withLoader = this.withLoader): Promise<TicketAttachment> {
		const formData = new FormData();
		formData.set('file', data);

		return this.getApi(withLoader)
			.post(`${this.baseUrl}/${commentId}/attachment/${uploadKey}`, formData)
		;
	}
}

export const useTicketComment = (ticketId: number) => new TicketCommentResource(`/api/ticket/${ticketId}/comment`);
