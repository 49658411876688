import { SelectOption } from '@/core/common';
import { ref, Ref } from 'vue';

export type FilterType = 'select' | 'date'; // More to come?

export default class Filter {
	public readonly id: string;
	public readonly type: FilterType;
	public readonly label: string;
	public readonly field: string;
	public readonly options: Ref<SelectOption[]>;
	public readonly value: Ref<string | number | null>;

	constructor(type: FilterType, label: string, field: string, options: Ref<SelectOption[]> | null = null) {
		this.id = Math.random().toString();
		this.type = type;
		this.label = label;
		this.field = field;
		if (options) {
			this.options = options;
		} else {
			this.options = ref([]);
		}
		this.value = ref(null);
	}

	public setOptions(options: SelectOption[]): Filter {
		this.options.value = options;

		return this;
	}

	public setValue(value: string | number | null): Filter {
		this.value.value = value;

		return this;
	}
}